import clsx from "clsx";
import styles from "./RegisterTemplatePage.module.css";
import { RegistrationForm } from "../organisms/RegistrationForm";
import pkg from "../../../package.json";

export const RegisterTemplatePage = () => {
  const year = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div
        role="presentation"
        aria-hidden="true"
        className={styles.logoContainer}
      >
        <img src="/edgeuno.svg" alt="Edgeuno's logo" width={190} height={120} />
      </div>
      <div
        role="presentation"
        aria-hidden="true"
        className={clsx("banner_bg", styles.banner)}
      />
      <RegistrationForm />
      <footer className="my-10 pb-10 md:py-12 md:my-0  mx-auto w-full text-center">
        <b className="uppercase">COPYRIGHT {year} EDGEUNO</b>
        <br />
        <span className="text-sm text-gray-600 font-medium">Version: {pkg.version}</span>
      </footer>
    </div>
  );
};
