class ApiClient {
  constructor(BASE_URL) {
    this.requestUrl = BASE_URL;
  }

  /**
   * @Param { string } url
   * @Param { Object } options
   * @Param { Object } options.body
   */
  get(url, options, raw = false) {
    if (raw) {
      return fetch(`${this.requestUrl}${url}`, {
        ...options,
        method: "GET",
      }).catch(this.handleRequestError);
    }

    return fetch(`${this.requestUrl}${url}`, {
      ...options,
      method: "GET",
    })
      .then(this.handleRequestSuccess)
      .catch(this.handleRequestError);
  }

  /**
   * @Param { string } url
   * @Param { Object } options
   * @Param { Object } options.body
   */

  post(url, options) {
    return fetch(`${this.requestUrl}${url}`, {
      ...options,
      method: "POST",
    })
      .then(this.handleRequestSuccess)
      .catch(this.handleRequestError);
  }

  /**
   * @Param { string } url
   * @Param { Object } options
   * @Param { Object } options.body
   */
  patch(url, options) {
    return fetch(`${this.requestUrl}${url}`, {
      ...options,
      method: "PATCH",
    })
      .then(this.handleRequestSuccess)
      .catch(this.handleRequestError);
  }
  /**
   * @Param { string } url
   * @Param { Object } options
   * @Param { Object } options.body
   */
  put(url, options) {
    return fetch(`${this.requestUrl}${url}`, {
      ...options,
      method: "PUT",
    })
      .then(this.handleRequestSuccess)
      .catch(this.handleRequestError);
  }

  handleRequestError(err) {
    console.error(err);
  }

  handleRequestSuccess(res) {
    return res.json();
  }
}

export const ApiClientInstance = new ApiClient(process.env.REACT_APP_BASE_API);
