import React, { useEffect } from "react";
import { QuizzAppTemplatePage } from "../components/templates/QuizzAppTemplatePage";
import { getCookie, removeCookie } from "../helpers";
import { USER_UUID_COOKIE } from "../constants";
import { useNavigate } from "react-router-dom";

export const QuizzAppPage = () => {
  const navigate = useNavigate();
  // User should be registered before accesing the quizz
  useEffect(() => {
    let uuid = getCookie(USER_UUID_COOKIE);
    if (!uuid || uuid === "undefined") {
      removeCookie(USER_UUID_COOKIE);
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <QuizzAppTemplatePage />;
};
