import { RegisterPage } from "../pages/RegisterPage";
import { QuizzAppPage } from "../pages/QuizzAppPage";

export const routes = [
  {
    path: "/",
    element: <RegisterPage />,
  },
  {
    path: "/quiz",
    element: <QuizzAppPage />,
  },
];
