// Function to set a cookie
export function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

// Function to remove a cookie
export function removeCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
}

// Function to check if a cookie exists
export function checkCookie(name) {
  return document.cookie.split(";").some((cookie) => {
    const trimmedCookie = cookie.trim();
    return trimmedCookie.startsWith(`${name}=`);
  });
}

// Function to get the value of a cookie
export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(`${name}=`)) {
      return trimmedCookie.substring(name.length + 1);
    }
  }
  return null;
}
