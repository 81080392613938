// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// import en from "./locales/en.json";
// import es from "./locales/es.json";
import br from "./locales/br.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "br", // Default language if a translation is not found
    // debug: true, // Enable debug mode
    resources: {
      // en: {
      //   translation: en,
      // },
      // es: {
      //   translation: es,
      // },
      br: {
        translation: br,
      },
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },

    react: {
      useSuspense: false, // Disable suspense mode
    },
  });

export default i18n;
