import { ApiClientInstance as axios } from "./ApiClient";

export const registerUser = async ({ name, email, phone, company, asn }) => {
  try {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("company", company);
    formData.append("asn", asn);

    // Register new user for the quizz app
    const res = await axios.post("/pto/signup", {
      body: formData,
    });

    return res;
  } catch {}
};

export const sendQuiz = async (data, uuid) => {
  try {
    // Sending quiz data
    const res = await axios.post("/pto/endquiz", {
      body: data,
      headers: new Headers({
        "Content-Type": "application/json",
        uuid,
        exam: "1",
      }),
    });

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getCertificatePreview = async (id) => {
  try {
    const res = await axios.get(
      `/pto/certificate/${id}`,
      {
        headers: new Headers({
          exam: "1",
        }),
      },
      true
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};
