import "react-phone-number-input/style.css";
import P from "react-phone-number-input";
import { t } from "i18next";
import clsx from "clsx";

export const PhoneInput = ({
  value,
  onChange,
  className = "",
  required = false,
}) => {
  return (
    <P
      value={value}
      required={required}
      onChange={onChange}
      placeholder={t("fields.phone.placeholder")}
      numberInputProps={{ className: clsx("base_input", className) }}
    />
  );
};
