import Quiz from "../../lib/Quiz";
import br from "../../i18n/locales/br.json";
import { sendQuiz } from "../../services";
import { USER_UUID_COOKIE } from "../../constants";
import { getCookie } from "../../helpers";
import { Button } from "../atoms/Button";
import { t } from "i18next";
import { Link } from "react-router-dom";

export const QuizzWizard = () => {
  const quiz = {
    nrOfQuestions: "5",
    questions: br.questions,
  };

  async function handleCompletedQuizz({ questions, userInput }) {
    const uuid = getCookie(USER_UUID_COOKIE);

    let inputedData = { answers: [] };
    // Formatting answers :)
    for (let i = 0; i < questions.length; i++) {
      let item = questions[i];
      inputedData.answers.push({
        question: item.question,
        answers: Array.isArray(userInput[i]) ? userInput[i] : `${userInput[i]}`,
      });
    }

    await sendQuiz(JSON.stringify(inputedData), uuid);
  }

  const ResultPage = ({
    correctPoints,
    numberOfCorrectAnswers,
    numberOfQuestions,
    numberOfIncorrectAnswers,
  }) => {
    return (
      <div className="mx-auto flex flex-col gap-4 items-center py-12 p-4 bg_congrats h-screen absolute top-0 left-0 translate-y-24 w-full">
        <div className="flex flex-col gap-2 items-center">
          {correctPoints < 60 ? (
            <div className="animate_from_scale">
              <img src="/ohh.svg" alt="" className="h-12 mb-4" />
            </div>
          ) : (
            <div className="animate_to_scale">
              <img src="/you_rock.svg" alt="" className="h-12 mb-4" />
            </div>
          )}
          {correctPoints < 60 ? (
            <div className="animate_from_scale">
              <img src="/badge-simple.svg" alt="" className="h-56" />
            </div>
          ) : (
            <div className="animate_to_scale">
              <img src="/badge.svg" alt="" className="h-56" />
            </div>
          )}
        </div>
        <div className="rounded-lg shadow-lg bg-white w-full max-w-lg mt-6">
          <header className="p-10 banner_bg rounded-t-lg text-center">
            <h2 className="text-white font-bold text-2xl md:text-4xl">
              {correctPoints < 60 ? t("results.fail") : t("results.success")}
            </h2>
          </header>
          <div className="flex justify-between items-center pt-14 gap-4 h-12 p-10 text-center">
            {Array.from({ length: numberOfCorrectAnswers }).map((t, i) => (
              <img
                key={`${t}-correct`}
                className="flex-1 object-contain h-7 md:h-10 animate-bounce"
                src="/star.svg"
                alt=""
              />
            ))}
            {Array.from({ length: numberOfIncorrectAnswers }).map((t) => (
              <img
                key={`${t}-incorrect`}
                className="flex-1 object-contain h-7 md:h-10"
                src="/star-line.svg"
                alt=""
              />
            ))}
          </div>
          <div className="text-lg text-center p-5">
            <p className="mb-2">
              {t("results.result", {
                correct: numberOfCorrectAnswers,
                total: numberOfQuestions,
              })}
            </p>
            <b className="text-2xl">
              {" "}
              {t("results.score")}: {correctPoints}
            </b>
          </div>
        </div>
        <footer className="flex gap-3">
          {correctPoints < 60 ? (
            <Button
              theme="border"
              className="hover:ring-2 hover:ring-gray-400 ring-offset-4 mt-4 retry_btn nextQuestionBtn"
              // eslint-disable-next-line no-restricted-globals
              onClick={() => location.reload()}
            >
              {t("results.retry")}
            </Button>
          ) : null}
          <Link
            to="/"
            className="hover:ring-2 hover:ring-gray-400 ring-offset-4 mt-4
            retry_btn border border-gray-400 bg-white btn_base text-center"
          >
            {t("results.new")}
          </Link>
        </footer>
      </div>
    );
  };

  return (
    <div>
      {/* <ResultPage
        correctPoints={20}
        numberOfQuestions={5}
        numberOfCorrectAnswers={1}
        numberOfIncorrectAnswers={4}
      /> */}
      <div className="flex items-center justify-center py-4">
        <Quiz
          quiz={quiz}
          shuffle={true}
          disableSynopsis={true}
          showInstantFeedback={true}
          showDefaultResult={false}
          onComplete={handleCompletedQuizz}
          customResultPage={ResultPage}
          appLocale={{
            question: "Pergunta",
            resultFilterAll: "Todas",
            nextQuestionBtn: "Próxima",
            prevQuestionBtn: "Anterior",
            startQuizBtn: "Iniciar Quiz",
            resultFilterCorrect: "Corretas",
            resultFilterIncorrect: "Incorretas",
            landingHeaderText: "<questionLength> Perguntas",
            resultPageHeaderText:
              "Você concluiu o quiz. Acertou <correctIndexLength> de <questionLength> perguntas.",
          }}
        />
      </div>
    </div>
  );
};
