import { InputGroup } from "../molecules/InputGroup";
import { PhoneInput } from "../molecules/PhoneInput";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Button } from "../atoms/Button";
import clsx from "clsx";
import { t } from "i18next";
import { registerUser } from "../../services";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../helpers";
import { USER_UUID_COOKIE } from "../../constants";
import { useState } from "react";

export const RegistrationForm = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // Im putting it here cause we may need i18n translate all this messages
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("fields.name.validationError")),
    email: Yup.string()
      .email(t("fields.email.validationError.error"))
      .required(t("fields.email.validationError.required")),
    phone: Yup.string().required(t("fields.phone.validationError")),
    company: Yup.string().required(t("fields.company.validationError")),
  });

  const handleSubmit = async (data) => {
    // Handle form submission logic here
    await registerUser(data).then(({ id, msg }) => {
      if (msg && !id) {
        setError(msg);
        return;
      }
      // Should only do this, if succeeds to register the user
      setCookie(USER_UUID_COOKIE, id, 2);
      navigate("/quiz");
    });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        company: "",
        asn: "",
      }}
      validationSchema={validationSchema}
      validateOnSubmit={true}
      onSubmit={handleSubmit}
    >
      {({ errors, values, isSubmitting, setFieldValue }) => (
        <Form className="bg-white shadow-md py-6 md:mt-10 rounded-md max-w-2xl w-full">
          <h2 className="text-2xl px-8 pt-8 font-bold pb-4 mb-4 border-b border-gray-100">
            {t("register.title")}
          </h2>
          <div className="px-8">
            <InputGroup label={t("register.fields.name")}>
              <Field
                required
                id="name"
                type="text"
                name="name"
                value={values.name}
                className={clsx(
                  "base_input",
                  errors.name ? "border-red-500" : ""
                )}
              />
              {errors.name && (
                <b className="text-red-500 text-sm">{errors.name}</b>
              )}
            </InputGroup>
            <InputGroup label={t("register.fields.email")}>
              <Field
                required
                id="email"
                type="text"
                name="email"
                value={values.email}
                className={clsx(
                  "base_input",
                  errors.email ? "border-red-500" : ""
                )}
              />
              {errors.email && (
                <b className="text-red-500 text-sm">{errors.email}</b>
              )}
            </InputGroup>
            <InputGroup label={t("register.fields.phone")}>
              <PhoneInput
                required
                value={values.phone}
                onChange={(v) => setFieldValue("phone", v)}
                className={clsx(errors.email ? "border-red-500" : "")}
              />
              {errors.phone && (
                <b className="text-red-500 text-sm">{errors.phone}</b>
              )}
            </InputGroup>
            <InputGroup label={t("register.fields.company")}>
              <Field
                required
                type="text"
                id="company"
                value={values.company}
                name="company"
                className={clsx(
                  "base_input",
                  errors.company ? "border-red-500" : ""
                )}
              />
              {errors.company && (
                <b className="text-red-500 text-sm">{errors.company}</b>
              )}
            </InputGroup>
            <InputGroup label={t("register.fields.asn")}>
              <Field
                id="asn"
                name="asn"
                type="text"
                value={values.asn}
                className={clsx("base_input")}
              />
            </InputGroup>
          </div>
          <div className="py-4">
            {error ? <p className="px-10 text-red-500">{error}</p> : null}
          </div>
          <footer className="flex items-center justify-end mt-4 border-t border-gray-100 px-8 py-4">
            <Button
              theme="alternative"
              disabled={isSubmitting}
              className="w-32 font-semibold"
            >
              {t("register.action.register")}
            </Button>
          </footer>
        </Form>
      )}
    </Formik>
  );
};
