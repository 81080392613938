import clsx from "clsx";

const themes = {
  primary: "",
  alternative: "btn-alternative",
  default: "border border-gray-200 hover:border-gray-300",
  border: "border border-gray-400 bg-white"
};

export const Button = ({
  children,
  className = "",
  theme = "default",
  ...props
}) => {
  return (
    <button
      type="submit"
      className={clsx("btn_base", className, themes[theme])}
      onTouchEnd={props.onClick}
      onClick={props.onClick}
      {...props}
    >
      {children}
    </button>
  );
};
