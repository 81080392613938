import clsx from "clsx";
import styles from "./QuizzAppTemplatePage.module.css";
import { QuizzWizard } from "../organisms/QuizzWizard";
import pkg from "../../../package.json";

const year = new Date().getFullYear();

export const QuizzAppTemplatePage = () => (
  <main>
    <header className={clsx(styles.header, "banner_bg")}>
      <img src="/edgeuno.svg" alt="Edgeuno's logo" width={120} height={90} />
      <div className="border-r border-white w-1 h-4" />
      <h1 className={styles.title}>Quiz App</h1>
    </header>
    <section className="px-4 pb-10">
      <QuizzWizard />
    </section>
    <footer className="my-10 pb-10 md:py-12 md:my-0  mx-auto w-full text-center">
      <b className="uppercase">COPYRIGHT {year} EDGEUNO</b>
      <br />
      <span className="text-sm text-gray-600 font-medium">
        Version: {pkg.version}
      </span>
    </footer>
  </main>
);
