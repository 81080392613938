export const InputGroup = ({ children, label }) => {
  return (
    <div className="mb-4">
      <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
        {label}
      </label>
      {children}
    </div>
  );
};
